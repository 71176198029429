import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  background: #fbfbf9;
  position: relative;
  padding-bottom: 120px;
  @media only screen and (max-width: 1440px) {
    padding-bottom: 120px;
  }
  @media only screen and (max-width: 1360px) {
    padding-bottom: 120px;
  }
  @media (max-width: 990px) {
    padding-bottom: 56px;
  }
  @media (max-width: 767px) {
    padding-bottom: 56px;
  }

  .container_wrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`

export const DetailsInner = styled.div`
  background: #f9f4ed;
  padding: 0px 60px;
  display: flex;
  @media only screen and (max-width: 991px) {
    padding: 50px 20px;
  }
`

export const Content = styled.div`
  margin: 0 auto;
  max-width: 730px;
  text-align: center;
  padding: 120px 0;
  @media only screen and (max-width: 991px) {
    padding: 56px 0px;
  }
  img {
    margin-bottom: 32px;
  }
  h1 {
    color: ${themeGet("colors.textColor", "#333333")};
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.96px;
    margin-bottom: 32px;
  }
  p {
    color: ${themeGet("colors.textColor", "#333333")};
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0px;
    letter-spacing: 0.32px;
  }

  span {
    @media only screen and (max-width: 480px) {
      padding: 0px;
    }
  }
`

export default SectionWrapper
