import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  background: #fbfbf9;
  position: relative;
  z-index: 0;
  padding-bottom: 186px;
  @media only screen and (max-width: 1440px) {
    padding-bottom: 186px;
  }
  @media only screen and (max-width: 1360px) {
    padding-bottom: 120px;
  }
  @media (max-width: 990px) {
    padding-bottom: 120px;
  }
  @media (max-width: 767px) {
    padding-bottom: 32px;
  }

  .container_wrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`

export const DetailsInner = styled.div`
  background: linear-gradient(
    to bottom,
    #fbfbf9 0%,
    #fbfbf9 15%,
    #fff 15%,
    #f9f4ed 15%,
    #f9f4ed 100%
  );
  padding: 0px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 991px) {
    flex-direction: column-reverse;
    padding: 50px 20px;
    background: #f9f4ed;
  }
`

export const Content = styled.div`
  margin-top: 80px;
  @media only screen and (max-width: 991px) {
    margin-top: 40px;
    width: 100%;
  }
  h3 {
    color: ${themeGet("colors.textColor", "#333333")};
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 1.28px;
    margin-bottom: 16px;
    @media only screen and (max-width: 600px) {
      font-size: 24px;
      line-height: 35px;
    }
  }
  p {
    color: ${themeGet("colors.textColor", "#333333")};
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 32px;
    max-width: 475px;
    @media only screen and (max-width: 991px) {
      max-width: 100%;
    }
  }

  .about_header {
    font-size: 16px;
    font-weight: 300;
    font-family: "bariol-regular";
    margin-bottom: 8px;
    margin-right: 8px;
  }

  span {
    @media only screen and (max-width: 480px) {
      padding: 0px;
    }
  }

  .seperator {
    letter-spacing: -0.14rem;
    color: ${themeGet("colors.secondaryColor", "#297E7F")};
    margin-top: 3px;
  }
`

export const ImageWrapper = styled.div`
  @media only screen and (max-width: 991px) {
    position: relative;
    width: 230px;
    height: 230px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
    border: 1px solid #e7e7e7;
  }

  img {
    /* position: absolute; */
    /* max-width: 580px; */
    @media only screen and (max-width: 991px) {
      width: 100%;
      height: auto;
      padding-top: 16px;
      margin-bottom: auto;
    }
  }
  > div {
    display: flex;
    @media only screen and (max-width: 480px) {
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 480px) {
    align-items: center;
  }
`

export const MiniHeader = styled.div`
  display: flex;
  .seperator {
    letter-spacing: -0.14rem;
    color: ${themeGet("colors.secondaryColor", "#297E7F")};
    margin-top: 3px;
  }
`

export default SectionWrapper
