import React from "react"
import Fade from "react-reveal/Fade"
import { Link } from "gatsby"

import TreatmentsPost from "../../components/TreatmentsPost"
import Arrow from "../../common/assets/image/elegant/back-arrow.svg"
import Container from "../../components/UI/Container"
import SectionWrapper, {
  TreatmentsWrapper,
  BackLink,
} from "./similarTreatments.style"

const SimilarTreatments = ({
  treatmentCategory,
  filteredSimilarTreatments,
}) => {
  function truncate(str, no_words) {
    return `${str.split(" ").splice(0, no_words).join(" ")}...`
  }
  return (
    <SectionWrapper>
      <Container width="1340px">
        <Fade up delay={100}>
          <h1 className="decorated">
            <span>Similar Treatments</span>
          </h1>
        </Fade>
        <TreatmentsWrapper>
          {filteredSimilarTreatments.map((item, index) => (
            <TreatmentsPost
              key={index}
              slug={item.node.slug}
              category={item.node.treatmentCategory}
              thumbUrl={item.node.treatmentThumbnailImage.fluid}
              title={item.node.treatmentTitle}
              excerpt={
                item.node.treatmentDescription.treatmentDescription.length < 160
                  ? item.node.treatmentDescription.treatmentDescription
                  : truncate(
                      item.node.treatmentDescription.treatmentDescription,
                      25
                    )
              }
            />
          ))}
        </TreatmentsWrapper>
        <Fade up delay={200}>
          <BackLink>
            <Link
              to={
                treatmentCategory === "aesthetics"
                  ? `/aesthetic-treatments-bournemouth-and-poole/`
                  : treatmentCategory === "slim"
                  ? `/slim-treatments-bournemouth-and-poole/`
                  : `/health-treatments-bournemouth-and-poole/`
              }
            >
              <img src={Arrow} alt="back button" />
              Back to{" "}
              <span>
                {treatmentCategory === "aesthetics"
                  ? "Aesthetic"
                  : treatmentCategory}
              </span>{" "}
              treatments
            </Link>
          </BackLink>
        </Fade>
      </Container>
    </SectionWrapper>
  )
}

export default SimilarTreatments
