import Fade from "react-reveal/Fade"
import Image from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

const TreatmentsPost = ({
  className,
  thumbUrl,
  title,
  excerpt,
  link,
  category,
  slug,
}) => {
  // Add all classes to an array
  const addAllClasses = ["treatments_post"]

  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  return (
    <Fade up delay={200}>
      <div className={addAllClasses.join(" ")}>
        <div className="thumbnail">
          <Image fluid={thumbUrl} alt={title} />
        </div>
        <div className="content">
          <h3 className="title">{title}</h3>
          <p className="excerpt">{excerpt}</p>
          {category === "conditions" ? (
            <Link
              to={`/${category}/${slug}/`}
              className="read_more"
              aria-label="read more"
            >
              Read more
            </Link>
          ) : (
            <Link
              to={`/${category}/${slug}/`}
              className="read_more"
              aria-label="read more about the treatment"
            >
              Read more about the treatment &rarr;
            </Link>
          )}
        </div>
      </div>
    </Fade>
  )
}

TreatmentsPost.propTypes = {
  className: PropTypes.string,
  thumbUrl: PropTypes.object,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  link: PropTypes.element,
  category: PropTypes.string,
  slug: PropTypes.string,
}

export default TreatmentsPost
