import Benefits from "../containers/Benefits"
import BreadcrumbTreatments from "../containers/BreadcrumbsTreaments/index.js"
import ChangeYourLife from "../containers/ChangeYourLife"
import { ContentWrapper } from "../containers/elegant.style"
import Faq from "../containers/Faq"
import Layout from "../containers/Layout"
import { Modal } from "@redq/reuse-modal"
import PageTitle from "../containers/PageTitle"
import React from "react"
import SEO from "../components/SEO"
import ShikhaComment from "../containers/ShikhaComment"
import SimilarTreatments from "../containers/SimilarTreatments"
import TestimonialMinimal from "../containers/TestimonialMinimal"
import TreatmentsIntro from "../containers/TreatmentsIntro"
import _ from "lodash"
import { graphql } from "gatsby"

const TreatmentsTemplate = ({
  data: { contentfulTreatments, allContentfulTreatments },
  location,
}) => {
  const {
    slug,
    treatmentCategory,
    treatmentDescription,
    treatmentImage,
    treatmentTestimonial,
    treatmentTitle,
    treatmentsExtraDetails,
    treatmentsShikhasComment,
    treatmentFaqs,
    treatmentBenefitsTitle,
    treatmentBenefits,
    treatmentSeoDetails,
  } = contentfulTreatments

  var filteredSimilarTreatments = _.filter(
    allContentfulTreatments.edges,
    function (item) {
      return item.node.slug !== slug
    }
  )

  const url = location.href ? location.href : ""

  return (
    <>
      <SEO
        url={url}
        image={`https:${treatmentImage.fluid.src}`}
        title={`${treatmentSeoDetails.metaTitle}`}
        shortDesc={`${treatmentSeoDetails.metaShortDescription}`}
        description={`${treatmentSeoDetails.metaDescription}`}
      />
      <Layout>
        <Modal />
        <ContentWrapper>
          <BreadcrumbTreatments
            treatmentTitle={treatmentTitle}
            slug={slug}
            treatmentCategory={treatmentCategory}
          />
          <PageTitle
            title={`${treatmentTitle}`}
            subtitle={
              treatmentCategory === "aesthetics"
                ? `Elegant Aesthetic`
                : `Elegant ${treatmentCategory}`
            }
          />
          <TreatmentsIntro
            treatmentDescription={treatmentDescription}
            treatmentImage={treatmentImage}
            title={treatmentTitle}
            location={location}
          />
          <Benefits
            treatmentBenefitsTitle={treatmentBenefitsTitle}
            treatmentBenefits={treatmentBenefits}
          />
          <TestimonialMinimal treatmentTestimonial={treatmentTestimonial} />
          <Faq
            treatmentFaqs={treatmentFaqs}
            treatmentsExtraDetails={treatmentsExtraDetails}
            treatmentCategory={treatmentCategory}
            treatmentTitle={treatmentTitle}
            slug={slug}
          />
          <ChangeYourLife
            title="Ready to start your journey with Elegant?"
            text="We’re here to help you feel your best. Explore our services, get in touch and book your consultation today."
          />
          <ShikhaComment treatmentsShikhasComment={treatmentsShikhasComment} />
          <SimilarTreatments
            treatmentCategory={treatmentCategory}
            filteredSimilarTreatments={filteredSimilarTreatments.slice(0, 3)}
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default TreatmentsTemplate

export const pageQuery = graphql`
  query TreatmentPostBySlug($slug: String!, $category: String!) {
    contentfulTreatments(slug: { eq: $slug }) {
      treatmentCategory
      treatmentTitle
      treatmentBenefitsTitle
      treatmentSeoDetails {
        metaTitle
        metaShortDescription
        metaDescription
      }
      treatmentBenefits {
        title
        description {
          json
        }
      }
      treatmentFaqs {
        question {
          question
        }
        answer {
          json
        }
      }
      treatmentDescription {
        treatmentDescription
      }
      treatmentsExtraDetails {
        description {
          json
        }
        title
      }
      treatmentsShikhasComment {
        details {
          details
        }
        title
      }
      treatmentTestimonial {
        clientsName
        text {
          text
        }
      }
      treatmentImage {
        fluid(quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      slug
    }
    allContentfulTreatments(
      filter: { treatmentCategory: { eq: $category } }
      limit: 4
      sort: { fields: updatedAt }
    ) {
      edges {
        node {
          treatmentTitle
          treatmentDescription {
            treatmentDescription
          }
          treatmentThumbnailImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          slug
          treatmentCategory
        }
      }
    }
  }
`
