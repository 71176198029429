import Fade from "react-reveal/Fade"
import React from "react"

import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Quote from "../../common/assets/image/elegant/quote.png"
import Text from "../../components/Text"
import SectionWrapper, {
  Content,
  DetailsInner,
} from "./testimonialMinimal.style"

const TestimonialMinimal = ({ treatmentTestimonial }) => {
  return (
    <>
      {treatmentTestimonial === null ? null : (
        <SectionWrapper>
          <Container width="1280px" className="container_wrapper">
            <Fade up delay={100}>
              <DetailsInner>
                <Content>
                  <Fade up delay={400}>
                    <img src={Quote} alt="quote" />
                    <Heading as="h1" content={treatmentTestimonial.text.text} />
                    <Text content={treatmentTestimonial.clientsName} />
                  </Fade>
                </Content>
              </DetailsInner>
            </Fade>
          </Container>
        </SectionWrapper>
      )}
    </>
  )
}

export default TestimonialMinimal
