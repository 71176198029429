import Fade from "react-reveal/Fade"
import React from "react"

import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import bannerPhoto from "../../common/assets/image/elegant/banner/Shikha-elegant-beauty-clinic.png"
import SectionWrapper, {
  Content,
  DetailsInner,
  ImageWrapper,
  MiniHeader,
} from "./shikhaComment.style.js"

const ShikhaComment = ({ treatmentsShikhasComment }) => {
  return (
    <>
      {treatmentsShikhasComment === null ? null : (
        <SectionWrapper>
          <Container width="1280px" className="container_wrapper">
            <Fade up delay={100}>
              <DetailsInner>
                <Content>
                  <Fade up delay={200}>
                    <MiniHeader>
                      <Text
                        content="Shikha's Comment"
                        className="about_header"
                      />
                      <span className="seperator">———</span>
                    </MiniHeader>
                  </Fade>
                  <Fade up delay={400}>
                    <Heading as="h3" content={treatmentsShikhasComment.title} />
                    <Text content={treatmentsShikhasComment.details.details} />
                  </Fade>
                </Content>
                <ImageWrapper>
                  <Fade up delay={100}>
                    <img src={bannerPhoto} alt="Shikha Banner" />
                  </Fade>
                </ImageWrapper>
              </DetailsInner>
            </Fade>
          </Container>
        </SectionWrapper>
      )}
    </>
  )
}

export default ShikhaComment
